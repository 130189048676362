import './DownloadIcon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import cvPdf from '../../files/Jacek_Wiacek_CV-ENG.pdf';

const DownloadIcon = (props) => {
  return (
    <div className="cv__download-icon">
      <a href={cvPdf} download="Jacek_Wiacek_CV" rel="noopener">
        <FontAwesomeIcon icon={faDownload} />
      </a>
    </div>
  );
};

export default DownloadIcon;
