import nowajaScreen from "../img/Screenshot_nowaja.png";
import recipeScreen from "../img/Screenshot_RecipeOfYourDay.png";
import metronomeScreen from "../img/Screenshot_Metronome.png";
import measureToolScreen from "../img/Screenshot_Measure_tool.png";
import snakeGameScreen from "../img/Screenshot_Snake_for_desktop.png";
import recipeReactScreen from "../img/Screenshot_RecipeOfYourDay-react.png";
import suitUpScreen from "../img/Screenshot_SuitUp.png";
import konceptScreen from "../img/Screenshot_koncept.png";
import ccTrackerScreen from "../img/Screenshot_CcTracker.png";

const CV_DATA = {
  carer: [
    {
      name: "job",
      company: "Publicis Le Pont",
      job: "Junior FrontEnd developer",
      dates: "2023.11- currently",
      responsibilities: [
        `Coperation on automation scripts based on typescript and Astro`,
        `NodeJS scripts improvements on current project templates`,
        `Developing project boilerplate based on Maizzle`,
        `Creating email templates using Maizzle Framework and tailwind`,
      ],
    },
    {
      name: "job",
      company: "Symkom Sp. z.o.o, Warsaw",
      job: "Account manager",
      dates: "2021.10- 2022.07",
      responsibilities: [
        `Providing professional support and advice to customers in case of
        engineering software (desktop and cloud)`,
        `Cooperation with foregin bussines partners`,
        `Identifying and analyzing customer needs`,
        `Generating leads and converting them into sales`,
      ],
    },
    {
      name: "job",
      company: "SOLIDEXPERT Polska Sp. z o.o. Sp.k., Warsaw",
      job: "Tech-Sales Specialist",
      dates: "2018.10- 2021.09",
      responsibilities: [
        `Providing professional support and advice to customers in case 
        of engineering software (desktop and cloud)`,
        `Cooperation with foregin bussines partners`,
        `Analyzing client needs to provide tailored software solutions and dedicated plugins`,
        `Generating leads and converting them into sales`,
      ],
    },
    {
      name: "job",
      company: "Vega, Jastków",
      job: "Mechanical Design Engineer",
      dates: "2018.03- 2018.09",
      responsibilities: [
        `Comprehensive design of machines for the food industry`,
        `Preparation of component specifications (2D and 3D documentations) -
          Solidworks`,
        `Cooperation with suppliers in the field of technical arrangements`,
        `Supervision over the construction of prototypes`,
      ],
    },
    {
      name: "job",
      company: "URSUS BUS S.A., Lublin",
      job: "Mechanical Design Engineer",
      dates: "2015.12- 2017.12",
      responsibilities: [
        `Adaptation of the urban bus structure for electric drive`,
        `preparation of component specifications (2D and 3D documentation) -
         Solidworks`,
        `Cooperation with suppliers in the field of technical arrangements`,
        `Developing of new solutions`,
      ],
    },
    {
      name: "job",
      company: "URSUS S.A., Lublin",
      job: "Trainee Engineer",
      dates: "2015.06- 2015.09",
      responsibilities: [
        `Mechanical Designer assistant -design mechanical components - Catia`,
      ],
    },
  ],
  education: [
    {
      name: "school",
      dates: "2020.01- 2021.01",
      school: "Warsaw School of Economics",
      profile: "Business Management, Postgraduate",
    },
    {
      name: "school",
      dates: "2015.03- 2017.06",
      school: "Lublin University of Technology",
      profile: `Mechanical Engineering, Master's degree`,
    },
    {
      name: "school",
      dates: "2012.10- 2015.02",
      school: "Lublin University of Technology",
      profile: `Mechanical Engineering, Bachelor`,
    },
  ],
  portfolio: [
    {
      name: "project",
      title: "konceptzieleni.com.pl",
      link: "https://konceptzieleni.com.pl/",
      github: null,
      picture: konceptScreen,
      description: `Commercial project of Two Language website for Envirnomental Architecture studio. If you are interested of similar project or you have a question, feel free to contact me via linkedin`,
      githubRepo: "private",
    },
    {
      name: "project",
      title: "nowajagabinet.pl",
      link: "https://nowajagabinet.pl/",
      github: null,
      description: `Commercial project of webside for Massage studio.  If you are interested of similar project or you have a question, feel free to contact me via linkedin`,
      picture: nowajaScreen,
    },
    {
      name: "project",
      title: "coinTracker",
      link: null,
      github: "https://github.com/graqu/CcTracker-official",
      description: `App using CoinGecko API to show most popular cryptocurrencies and show their charts. Users have possibility to Follow most favourite coins, calculate fiat currency value of all portfolio. Additionally user can save own savings data and import to calculate current value. Techstack used to this is: Typescript, React, Vite, tailwind`,
      picture: ccTrackerScreen,
    },
    {
      name: "project",
      title: "Suit shop",
      link: "https://suitup.netlify.app/",
      github: "https://github.com/graqu/SuitUpShop",
      picture: suitUpScreen,
      description: `Demo of online shop with suits. App was made in React.js. I also used google Firebase API to store and fetch products data. For prices i used Polish Bank API (NBP) to calculate EUR and USD course by them current values (depends on user settings)`,
      githubRepo: `https://github.com/graqu/SuitUpShop`,
    },
    {
      name: "project",
      title: "Cookbook",
      link: "https://graqu.github.io/Recipe_of_your_day/",
      github: "https://github.com/graqu/Recipe_of_your_day",
      description: `Demo app which can help users to store their favourite reciepes and modify (edit or delete them). App was made in vanilla JS and for storing data it use localStorage`,
      picture: recipeScreen,
    },
    {
      name: "project",
      title: "Cookbook in React",
      link: "https://recipeofyourday.netlify.app/",
      github: "https://github.com/graqu/Recipes-react",
      description: `Demo app based on my previous project in Vanilla JS but remade in react and additionally with use of bootstrap`,
      picture: recipeReactScreen,
    },
    {
      name: "project",
      title: "Metronome",
      link: "https://graqu.github.io/Metronome/",
      github: "https://github.com/graqu/Metronome",
      description: `Because I am muscian, this app was must have in my portfolio. To improve my skills with timeouts and interwals i decided to make metronome which helps me and other musicians to play in correct tempo. Now your teacher never will say "not quite my tempo!"`,
      picture: metronomeScreen,
    },
    {
      name: "project",
      title: "Measure tool for developers - (press M and Z)",
      link: "https://graqu.github.io/Measure_tool/",
      github: "https://github.com/graqu/Measure_tool",
      description: `The best practise for me on the beggining was to make websites based on finished projects (screenshots). To make my projects pixel perfect i created a tool wchich add functionality to measure site by dev. Give user possibility to easily check global and relative dimensions. All you need to do is copy script from readme and delete it when you will finish.`,
      picture: measureToolScreen,
    },
    {
      name: "project",
      title: "Snake for Desktop",
      link: "https://graqu.github.io/Snake-Game---pure-JS/",
      github: "https://github.com/graqu/Snake-Game---pure-JS",
      picture: snakeGameScreen,
      description: ` My own job here was to add player settings and scoring functionality. The core of this app was made with help of Web Dev Simplified video tutorial.`,
    },
  ],
  contact: [
    {
      name: "contact",
      message: `I'm a dedicated frontend developer with nearly a year of experience, eager to join a professional team. Passionate about learning new skills and tackling ambitious projects, I'm ready to contribute to creating outstanding user experiences. Let’s build something great together!`,
      phone: "+48 691 099 961",
      mail: "wiacekjacek2@gmail.com",
      linkedin: "https://www.linkedin.com/in/jacekwiacek/",
      github: "https://www.github.com/graqu",
      hobby: [
        `Guitar playing`,
        ` Web development (Frontend)`,
        ` Heavy Music`,
        ` Psychological and self developement books`,
        ` Gaming`,
      ],
      allSkills: [
        `javaScript`,
        `TypeScript`,
        ` HTML and CSS`,
        ` npm`,
        ` SCSS`,
        ` React`,
        ` Vue`,
        ` Node JS`,
        ` Astro.js`,
        ` Bootstrap`,
        ` Tailwind`,
        ` Maizzle`,
        ` Figma`,
        ` Business Relationship Management`,
        ` Collaborative team work`,
        ` Salesforce`,
        ` Solidworks - CSWP`,
        ` Catia`,
      ],
      languages: [`English`, ` German - Basics`, ` Polish - Native`],
    },
  ],
};

export default CV_DATA;
