import CategoryHeading from '../components/CategoryContent/CategoryHeading';
import ListingContent from '../components/CategoryContent/ListingContent';
import CV_DATA from '../data/CvData';

const EducationPage = () => {
  return (
    <>
      <CategoryHeading>Education</CategoryHeading>
      <ListingContent data={CV_DATA.education} />
    </>
  );
};

export default EducationPage;
