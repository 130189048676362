import { createPortal } from 'react-dom';
import React from 'react';

import styles from './Modal.module.css';

const Modal = (props) => {
  return (
    <>
      {createPortal(
        <div className={styles.backdrop} onClick={props.onClose}>
          <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
            {props.children}
          </div>
        </div>,
        document.getElementById('overlay-root'),
      )}
    </>
  );
};

export default Modal;
