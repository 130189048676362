import SkillsBlock from '../components/SkillsBlock/SkillsBlock';
import PersonalPhoto from '../components/PersonalPhoto/PersonalPhoto';
import DownloadIcon from '../components/DownloadIcon/DownloadIcon';
import Footer from '../components/Footer/Footer';
import CvHeading from '../components/CvHeading/CvHeading';
import { Outlet } from 'react-router-dom';
import CategoryFlags from '../components/CategoryFlags/MainNavigation';

const RootLayout = () => {
  return (
    <>
      <div className="App">
        <DownloadIcon />
        <main className="main">
          <div className="cv">
            <CategoryFlags />
            <PersonalPhoto />
            <CvHeading />
            <Outlet />
            <SkillsBlock />
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default RootLayout;
