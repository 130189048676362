import CategoryHeading from '../components/CategoryContent/CategoryHeading';
import ListingContent from '../components/CategoryContent/ListingContent';
import CV_DATA from '../data/CvData';

const JobExperiencePage = () => {
  return (
    <>
      <CategoryHeading>Job experience</CategoryHeading>
      <ListingContent data={CV_DATA.carer} />
    </>
  );
};

export default JobExperiencePage;
