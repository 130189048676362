import { useState } from 'react';
import CategoryHeading from '../components/CategoryContent/CategoryHeading';
import PortfolioBlock from '../components/Portfolio/PortfolioBlock';
import Modal from '../components/UI/Modal';
import ProjectDecription from '../components/ProjectDescription/ProjectDescription';
import CV_DATA from '../data/CvData';

const HomePage = () => {
  const portfolioData = CV_DATA.portfolio;

  const [modalState, setModalState] = useState({
    active: false,
    currentProject: '',
  });
  const modalOpenHandler = (dataToShow) => {
    setModalState({ active: true, currentProject: dataToShow });
  };

  const closeModal = () => {
    setModalState({ active: false, currentProject: '' });
  };

  return (
    <>
      <CategoryHeading>My portfolio</CategoryHeading>
      {modalState.active && (
        <Modal onClose={closeModal}>
          <ProjectDecription
            item={modalState.currentProject}
            onClose={closeModal}
          />
        </Modal>
      )}
      <PortfolioBlock data={portfolioData} onModalOpen={modalOpenHandler} />
    </>
  );
};

export default HomePage;
