import {
  faMobileScreenButton,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MediaQuery from 'react-responsive';

import './MoreInfoBlock.css';

const MoreInfoBlock = (props) => {
  const allData = props.data[0];

  const copyToClipboard = (value) => {
    const element = document.querySelector(`#${value}`);
    const valueToCopy = element.innerHTML;

    const storage = document.createElement('textarea');
    storage.value = valueToCopy;
    element.appendChild(storage);

    storage.select();
    storage.setSelectionRange(0, 99999);
    document.execCommand('copy');
    element.removeChild(storage);
    element.textContent = 'copied!';
    setTimeout(() => {
      element.textContent = valueToCopy;
    }, 500);
  };

  return (
    <div className="cv__more-info">
      <MediaQuery maxWidth={768}>
        <p className="cv__more-info-desc">{allData.message}</p>
      </MediaQuery>
      <h4>All my skills</h4>
      <p>{allData.allSkills.toString()}</p>
      <h4>Languages </h4>
      <p>{allData.languages.toString()}</p>
      <h4>My Hobbies </h4>
      <p>{allData.hobby.toString()}</p>
      <div className="cv__more-info-contact">
        <div className="cv__more-info-contact-left">
          <FontAwesomeIcon
            icon={faMobileScreenButton}
            className="cv__more-info-contact-icon"
          />
          <div className="cv__more-info-contact-item">
            <p id="phone">{allData.phone}</p>
            <button
              onClick={() => copyToClipboard('phone')}
              className="cv__more-info-btn">
              copy
            </button>
          </div>

          <FontAwesomeIcon
            icon={faEnvelope}
            className="cv__more-info-contact-icon"
          />
          <div className="cv__more-info-contact-item">
            <p id="email">{allData.mail}</p>
            <button
              onClick={() => copyToClipboard('email')}
              className="cv__more-info-btn">
              copy
            </button>
          </div>
        </div>
        <div className="cv__more-info-contact-right">
          <a href={allData.linkedin} target="_blank" rel="noopener noreferrer">
            Linkedin
          </a>
          <a href={allData.github} target="_blank" rel="noopener noreferrer">
            Github
          </a>
        </div>
      </div>
    </div>
  );
};

export default MoreInfoBlock;
