import CategoryHeading from '../components/CategoryContent/CategoryHeading';
import CV_DATA from '../data/CvData';
import MoreInfoBlock from '../components/MoreInfoBlock/MoreInfoBlock';

const MoreInfoPage = () => {
  return (
    <>
      <CategoryHeading>More about me</CategoryHeading>
      <MoreInfoBlock data={CV_DATA.contact} />
    </>
  );
};

export default MoreInfoPage;
