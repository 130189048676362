import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CategoryFlag.module.css';
import { NavLink } from 'react-router-dom';

const CategoryFlag = (props) => {
  return (
    <NavLink
      to={props.direction}
      className={({ isActive }) =>
        isActive
          ? `${styles['category-flag']} ${styles.active}`
          : styles['category-flag']
      }
    >
      <FontAwesomeIcon icon={props.icon} />
    </NavLink>
  );
};

export default CategoryFlag;
