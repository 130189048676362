import './PortfolioBlock.css';
import PortfolioCard from './PortfolioCard';

const PortfolioBlock = (props) => {

	return (
		<>
			<div className='cv__portfolio'>
				{props.data.map((item) => {
					return (
						<PortfolioCard
							itemData={item}
							key={Math.random()}
							onClick={props.onModalOpen}
						/>
					);
				})}
			</div>
		</>
	);
};

export default PortfolioBlock;
