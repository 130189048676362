import './SkillsBlock.css';
import SkillsItem from './SkillsItem';

const SkillsBlock = (props) => {
  const techstack = [`HTML`, `CSS`, `TypeScript`,  `React`,`Vue`,`SASS`, `GIT`];

  return (
    <div className="cv__skills-block">
      <h3>My Technology Stack:</h3>
      {techstack.map((tech) => {
        return <SkillsItem tech={tech} key={techstack.indexOf(tech)} />;
      })}
    </div>
  );
};

export default SkillsBlock;
