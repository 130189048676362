import './CvHeading.css';

const CvHeading = () => {
  return (
    <div className="cv__heading">
      <h1>Jacek Wiącek</h1>
      <p>Frontend developer</p>
      <hr></hr>
    </div>
  );
};

export default CvHeading;
