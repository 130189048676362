import './SkillsItem.css';

const SkillsItem = (props) => {
  return (
    <div className="skills-block__item">
      <p>{props.tech}</p>
    </div>
  );
};

export default SkillsItem;
