import './ListingItem.css';

const ListingItem = (props) => {
  return (
    <li>
      <div className="listing-item">
        <p className="listing-date">{props.data.dates}</p>
        <div>
          <h3 className="listing-heading">
            {props.data.job || props.data.school}
          </h3>
          <p className="listing-details">
            {props.data.company || props.data.profile}
          </p>
          {!!props.data.responsibilities && (
            <ul className="listing-description">
              {props.data.responsibilities.map((element) => {
                return (
                  <li key={props.data.responsibilities.indexOf(element)}>
                    {element}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </li>
  );
};

export default ListingItem;
