import { Link } from 'react-router-dom';
import CategoryHeading from '../components/CategoryContent/CategoryHeading';
import CvHeading from '../components/CvHeading/CvHeading';
import PersonalPhoto from '../components/PersonalPhoto/PersonalPhoto';

const ErrorPage = () => {
  return (
    <div className="App">
      <main className="main">
        <div className="cv">
          <CvHeading />
          <CategoryHeading>Site doesn't exist</CategoryHeading>
          <Link to="/">back to the main site</Link>
        </div>
      </main>
    </div>
  );
};

export default ErrorPage;
