import styles from './ProjectDescription.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faSquareGithub } from '@fortawesome/free-brands-svg-icons';

const ProjectDecription = (props) => {
  const item = {
    name: 'project',
    title: props.item.title,
    link: props.item.link,
    picture: props.item.picture,
    description: props.item.description,
    visitLink: props.item.link,
    githubRepo: props.item.github,
  };

  return (
    <div className={styles.project}>
      <button className={styles.close} onClick={props.onClose}>
        <FontAwesomeIcon icon={faXmark} />
      </button>
      <h2 className={styles['project-heading']}>{item.title}</h2>
      <div className={styles['main-box']}>
        <div className={styles.image}>
          <img src={item.picture} alt={`${item.name} screenshot`} />
        </div>
        <div>
          <p className={styles.description}>{item.description}</p>
          <div className={styles.links}>
            <a
              class={styles.link}
              href={item.visitLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faGlobe} />
              <p>visit site</p>
            </a>
            {item.githubRepo && (
              <a
                href={item.githubRepo}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                <FontAwesomeIcon icon={faSquareGithub} />
                <p>github repo</p>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDecription;
