import './PortfolioCard.css';

const PorfolioCard = (props) => {
  const showInfoHandler = () => {
    props.onClick(props.itemData);
  };

  return (
    <div className="cv__portfolio-card" onClick={showInfoHandler}>
      {/* <a
				className='cv__portfolio-card-link'
				href={props.itemData.link}
				target='_blank'
				rel='noopener noreferrer'
			>
				link
			</a> */}
      <div className="cv__portfolio-card-img">
        <img
          src={props.itemData.picture}
          alt={`${props.itemData.title}-screenshot`}
        />
      </div>
      <div className="cv__portfolio-card-name">
        <p>{props.itemData.title}</p>
      </div>
    </div>
  );
};

export default PorfolioCard;
