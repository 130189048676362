import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div>
        Copyright © 2024 |
        <a
          href="https://www.linkedin.com/in/jacekwiacek/"
          target="blank"
          rel="noopener noreferer"
        >
          Jacek Wiącek
        </a>
      </div>
    </footer>
  );
};

export default Footer;
