import { useState } from 'react';
import './styles.css';
import HomePage from './pages/Homepage';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import RootLayout from './pages/RootLayout';
import JobExperiencePage from './pages/JobExperiencePage';
import EducationPage from './pages/EducationPage';
import MoreInfoPage from './pages/MoreInfoPage';
import ErrorPage from './pages/ErrorPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'job-experience',
        element: <JobExperiencePage />,
      },
      {
        path: 'education',
        element: <EducationPage />,
      },
      {
        path: 'more-info',
        element: <MoreInfoPage />,
      },
    ],
  },
]);

export default function App() {
  const [currentCategory, setCurrentCategory] = useState(0);
  const [projectModalState, setProjectModalState] = useState({
    open: false,
    portfolioItem: {
      name: '',
      title: '',
      link: '',
      picture: '',
    },
  });

  return <RouterProvider router={router} />;
}
