import CategoryFlag from './CategoryFlag';
import './CategoryFlags.css';
import {
  faUserGraduate,
  faPen,
  faSuitcase,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

const CategoryFlags = () => {
  return (
    <div className="cv__category-flags">
      <CategoryFlag icon={faPen} direction="/" />
      <CategoryFlag icon={faSuitcase} direction="/job-experience" />
      <CategoryFlag icon={faUserGraduate} direction="/education" />
      <CategoryFlag icon={faUser} direction="/more-info" />
    </div>
  );
};

export default CategoryFlags;
