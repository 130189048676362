import MediaQuery from 'react-responsive';
import { useState } from 'react';
import profilePicture from '../../img/jacek-photo.jpg';
import CV_DATA from '../../data/CvData';

import './PersonalPhoto.css';

const PersonalPhoto = () => {
  const [descriptionStatus, setDescriptionStatus] = useState(false);

  const contactData = CV_DATA.contact;

  const handleDescription = () => {
    descriptionStatus === false
      ? setDescriptionStatus(true)
      : setDescriptionStatus(false);
  };

  return (
    <div className="personal-photo">
      <img src={profilePicture} alt="jacek" />
      <MediaQuery minWidth={768}>
        <div className="personal-photo-info-icon" onClick={handleDescription}>
          {descriptionStatus ? 'X' : '?'}
        </div>
        {descriptionStatus && (
          <div className="personal-photo-info">
            <p>{contactData[0].message}</p>
          </div>
        )}
      </MediaQuery>
    </div>
  );
};

export default PersonalPhoto;
