import './ListingContent.css';
import ListingItem from './ListingItem';

const ListingContent = (props) => {
  return (
    <>
      <ul className="listing-block-type">
        {props.data.map((item) => {
          return <ListingItem data={item} key={props.data.indexOf(item)} />;
        })}
      </ul>
    </>
  );
};

export default ListingContent;
